<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab
        active
        lazy
      >
        <template #title>
          <feather-icon icon="FileIcon" />
          <span>Kasko Poliçesi</span>
        </template>
        <b-card no-body>
          <kasko-list />
        </b-card>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="FileIcon" />
          <span>Trafik Sigortası</span>
        </template>
        <b-card no-body>
          <trafik-list />
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'
import KaskoList from '@/views/Rental/InsuranceWatchList/IptalPolice/KaskoList.vue'
import TrafikList from '@/views/Rental/InsuranceWatchList/IptalPolice/TrafikList.vue'

export default {
  name: 'Index',
  components: {
    BTabs,
    BTab,
    BCard,
    KaskoList,
    TrafikList,
  },
}
</script>
